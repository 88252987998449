import React, { useCallback, useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import { visuallyHidden } from '@mui/utils';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline, Modal } from '@mui/material';
// import Prismic from '@prismicio/client';
// eslint-disable-next-line
import { Document } from '@prismicio/client/types/documents';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import './App.css';

import hr from './assets/images/eu/hr.png';
import eu from './assets/images/eu/eu.png';
import ruralniRazvoj from './assets/images/eu/ruralni-razvoj.jpeg';

const cards = [
  {
    image: require(`assets/images/kombajn-siroki-plan.jpg`),
    alt: 'kombajn u širokom planu',
    title: 'Vršidba uljane repice 2021',
    description: 'Kombajn u polju uljane repice.',
  },
  {
    image: require(`assets/images/kombajn-zoom.jpg`),
    alt: 'kombajn sa osobom',
    title: 'Vršidba uljane repice 2021',
    description: 'Vlastitim prijevozom.',
  },
  {
    image: require(`assets/images/traktor-dvije-prikolice.jpg`),
    alt: 'traktor sa dvije prikolice u krupnom planu',
    title: 'Gnojivo',
    description: 'Utovar mineralnog gnojiva.',
  },
  {
    image: require(`assets/images/traktor-fokus.jpg`),
    alt: 'traktor u fokusu',
    title: 'Massey Ferguson',
    description: 'Prijevoz pivskog ječma',
  },
  {
    image: require(`assets/images/traktor-kosenje.jpg`),
    alt: 'traktor prilikom košenja trave',
    title: 'Malčer',
    description: 'Uklanjanje raslinja te povećanje obradivih površina.',
  },
  {
    image: require(`assets/images/new-holland.jpg`),
    alt: 'new holland traktor',
    title: 'New Holland',
    description:
      'Baveći se vlastitom poljoprivrednom proizvodnjom dugi niz godina odlučili smo našu ponudu proširiti na uslužne djelatnosti u poljoprivredi. Europski fondovi te program ruralnog razvoja omogućili su nam nabavku mehanizacije neophodne za tu vrstu posla. Mjerom 6.2.1. Potpora razvoju nepoljoprivrednih djelatnosti u ruralnim područjima postali smo vlasnici traktora New Holland T5 105, koji je prikladan za obavljanje takvih poslova.',
  },
];

const crops = [
  {
    image: require(`assets/images/crops/kukuruz.webp`),
    alt: 'Slika kukuruza',
    title: 'Kukuruz',
    description:
      'Uz pšenicu kultura od koje je sve počelo. Za OPG Mrmoš Veseljko uzgoj kukuruza imao je velik značaj od samog početka poljoprivredne proizvodnje jer je predstavljao vrlo bitnu namirnicu u ishrani mliječnih krava. Njegov kvaliteta i odsutnost aflatoksina značile su otkup mlijeka te osiguravanje rasta gospodarstva do današnjih razmjera.',
  },
  {
    image: require(`assets/images/crops/pivski-jecam.webp`),
    alt: 'Slika pivskog ječma',
    title: 'Pivski ječam',
    description:
      'Možda i najmanje zahtjevan ali zasigurno ništa manje vrijedan. Na našem gospodarstvu najmlađa kultura. Ograničen broj prikupnih mjesta žetvu zna produljiti nekoliko dana. Teži se proizvoditi ne manje od 2300 kg/ha što nam je do sada uspijevalo.',
  },
  {
    image: require(`assets/images/crops/psenica.webp`),
    alt: 'Slika pšenice',
    title: 'Pšenica',
    description:
      'Iz godine u godinu povećavamo postotak površina pod pšenicom, strahujući od ljetnih suša pšenica daje tračak sigurnosti budući da se radi o jesenjoj sjetvi. Uz kvantitet, veći značaj dajemo kvalitetu, uz dobar prinos uspijevamo zadovoljiti stroge kriterije sadržaja proteina, uz povoljne vremenske prilike hektolitar kao parametar kakvoće robe uspijevamo održati na željenoj razini.',
  },
  {
    image: require(`assets/images/crops/suncokret.webp`),
    alt: 'Slika suncokreta',
    title: 'Suncokret',
    description:
      'Koristeći nove sorte kod kojih je tretman herbicidima dozvoljen i moguć suncokret je postao značajna kultura na našim poljima. OPG Mrmoš Veseljko posjeduje potrebitu mehanizaciju za proizvodnju suncokreta. Od sijanja, održavanja, vršidbe pa sve do prijevoza koji je postao moguć uz europske fondove suncokret sa našeg OPG stiže na otkupna mjesta.',
  },
  {
    image: require(`assets/images/crops/uljana-repica.webp`),
    alt: 'Slika uljane repice',
    title: 'Uljana repica',
    description:
      'Vodeći računa o kakvoći zemljišta te održivoj poljoprivrednoj proizvodnji naš OPG pribjegava zelenoj gnojidbi koristeći uljanu repicu. Osim gnojidbe ova se kultura nalazi na popisu proizvedenih dobara našeg gospodarstva.',
  },
];

const projectDescription =
  'Budući da težimo stalnom unapređenju našeg gospodarstva, financijskom i tehnološkom, odlučili smo se da prihode povećamo kroz pružanje usluga u poljoprivredi. Do sada nismo imali ovu dijelatnost. Na tržištu se pojavila povećana potreba za ovom djelatnošću, s toga smo odlučili upotpuniti našu ponudu. Kako bismo bili što bolji u obavljanju svog posla, neophodno se pokazala nabavka novog traktora, što smo kroz fond europske unije (mjera 6.2.1.) i ostvarili. Nabavljen je novi traktor marke New Holland snage nešto iznad 105 konja koji svojim karakteristikama udovoljava novostvorenim potrebama.';

const minFontSize = 12;
const maxFontSize = 16;

const logoBreakpoint = 400;

const App: React.FC = () => {
  const [enableDyslexia, setDyslexia] = useState(false);
  const [enableImpaired, setImpaired] = useState(false);
  const [fontSize, setFontSize] = useState(minFontSize);
  const [isEUModalOpen, setEUModalOpen] = useState(false);
  const [dynamicCards] = useState<Document[]>([]);
  const { innerWidth: width } = window;

  const theme = createTheme({
    typography: {
      fontFamily: enableDyslexia ? 'OmoType' : 'Roboto',
      fontSize: fontSize,
    },
    palette: { mode: enableImpaired ? 'dark' : 'light' },
  });

  const openEUModal = useCallback(() => {
    setEUModalOpen(true);
    window.location.hash = 'eu';
  }, [setEUModalOpen]);

  const closeEUModal = () => {
    setEUModalOpen(false);
    window.location.hash = '';
  };

  const toggleDyslexia = () => {
    setDyslexia(!enableDyslexia);
    localStorage.setItem('enableDyslexia', (!enableDyslexia).toString());
  };
  const toggleImpaired = () => {
    setImpaired(!enableImpaired);
    localStorage.setItem('enableImpaired', (!enableImpaired).toString());
  };
  const setFontSizeNormal = () => {
    setFontSize(minFontSize);
    localStorage.setItem('fontSize', `${minFontSize}`);
  };
  const setFontSizeBig = () => {
    setFontSize(maxFontSize);
    localStorage.setItem('fontSize', `${maxFontSize}`);
  };

  useEffect(() => {
    if (localStorage.getItem('enableDyslexia') === 'true') {
      setDyslexia(true);
    }

    if (localStorage.getItem('enableImpaired') === 'true') {
      setImpaired(true);
    }

    const fontSize = localStorage.getItem('fontSize');
    if (fontSize) {
      setFontSize(Number.parseInt(fontSize));
    }

    if (window.location.hash === '#eu') {
      openEUModal();
    }

    // const getData = async () => {
    //   const prismic = Prismic.client('https://opg-mrmos.prismic.io/api/v2');
    //   const api = await prismic.api.get();
    //   const results = await api.query('');
    //   setDynamicCards(results.results);
    // };
    // getData(); // TODO: MAYBE ADD LATER
  }, [setDyslexia, setImpaired, setFontSize, openEUModal]);
  console.log(dynamicCards);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar>
          <Button
            variant="contained"
            onClick={toggleDyslexia}
            aria-label="Uključivanje fonta za disleksiju"
          >
            DYX
          </Button>
          <Button
            variant="contained"
            aria-label="Manja slova"
            onClick={setFontSizeNormal}
            disabled={fontSize === minFontSize}
          >
            <Typography fontSize={minFontSize}>A</Typography>
          </Button>
          <Button
            variant="contained"
            aria-label="Veća slova"
            onClick={setFontSizeBig}
            disabled={fontSize === maxFontSize}
          >
            <Typography fontSize={maxFontSize}>A</Typography>
            {/* <Box sx={visuallyHidden}>Veća slova</Box> */}
            <div style={visuallyHidden}>veća slova</div>
          </Button>
          <IconButton
            aria-label="Uključivanje prilagodbe pristupačnosti"
            onClick={toggleImpaired}
          >
            {enableImpaired ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        </Toolbar>
      </AppBar>
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 8,
            pb: 2,
          }}
        >
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h1"
              align="center"
              color="text.primary"
              gutterBottom
              sx={{ fontWeight: 'bold' }}
            >
              OPG Mrmoš
            </Typography>
            <Typography
              variant="h5"
              align="center"
              color="text.secondary"
              paragraph
            >
              Obiteljsko poljoprivredno gospodarstvo
            </Typography>
          </Container>
        </Box>
        <Container sx={{ py: 8 }} maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={4} sx={{ mb: 4 }}>
            {cards.map((card, index) => (
              <Grid item key={index} xs={12} sm={6} md={4}>
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <CardMedia
                    component="img"
                    height="150"
                    image={card.image.default}
                    alt={card.alt}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      sx={{ fontWeight: 'bold' }}
                    >
                      {card.title}
                    </Typography>
                    <Typography sx={{ textAlign: 'center' }}>
                      {card.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
            {dynamicCards.map((dynamicCard, index) => (
              <Grid item key={index + cards.length} xs={12} sm={6} md={4}>
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <CardMedia
                    component="img"
                    height="150"
                    image={dynamicCard.data.card_image.url}
                    alt={dynamicCard.data.card_image_alt[0].text}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      sx={{ fontWeight: 'bold' }}
                    >
                      {dynamicCard.data.card_title[0].text}
                    </Typography>
                    <Typography>
                      {dynamicCard.data.card_description[0].text}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Typography
            component="h1"
            variant="h3"
            align="center"
            color="text.primary"
            gutterBottom
            sx={{ fontWeight: 'bold' }}
          >
            Žitarice
          </Typography>
          <Typography
            variant="h5"
            align="center"
            color="text.secondary"
            paragraph
          >
            Žitarice koje obrađujemo
          </Typography>
          <Grid container spacing={4}>
            {crops.map((crop, index) => (
              <Grid item key={index} xs={12} sm={6} md={4}>
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <CardMedia
                    component="img"
                    height="150"
                    image={crop.image.default}
                    alt={crop.alt}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      sx={{ fontWeight: 'bold' }}
                    >
                      {crop.title}
                    </Typography>
                    <Typography sx={{ textAlign: 'center' }}>
                      {crop.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
      {/* Footer */}
      <Box
        sx={{
          bgcolor: 'background.paper',
          p: 4,
          position: 'sticky',
          bottom: 0,
          boxShadow: 20, // box-shadow: 0 10px 20px 0px black;
        }}
        component="footer"
      >
        {/* <Typography variant="h6" align="center" gutterBottom>
          Podnožje
        </Typography> */}
        {/* <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
          Izradu internetske stranice sufinancirala je Europska unija iz
          Europskog fonda za regionalni razvoj.
        </Typography> */}

        <Container
          maxWidth="md"
          style={{ cursor: 'pointer', textAlign: 'center' }}
        >
          {width <= logoBreakpoint && (
            <img
              style={{ maxHeight: '90px', minHeight: '60px', height: '15vw' }}
              src={ruralniRazvoj}
              alt="Logo ruralni razvoj"
            />
          )}
          <Grid
            container
            spacing={4}
            onClick={openEUModal}
            style={{ cursor: 'pointer', textAlign: 'center' }}
          >
            <Grid item xs>
              <img
                style={{ maxHeight: '90px', minHeight: '60px', height: '15vw' }}
                src={hr}
                alt="Slika HR zastave"
              />
            </Grid>
            {width > logoBreakpoint && (
              <Grid item xs>
                <img
                  style={{
                    maxHeight: '90px',
                    minHeight: '60px',
                    height: '15vw',
                  }}
                  src={ruralniRazvoj}
                  alt="Logo ruralni razvoj"
                />
              </Grid>
            )}
            <Grid item xs>
              <img
                style={{ maxHeight: '90px', minHeight: '60px', height: '15vw' }}
                src={eu}
                alt="Slika EU zastave"
              />
            </Grid>
          </Grid>
        </Container>
        <Copyright />
      </Box>
      {/* End footer */}
      <Modal
        open={isEUModalOpen}
        onClose={closeEUModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '10%',
            left: '10%',
            transform: 'translate(-5%, -5%)',
            width: '90%',
            height: '90%',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            overflow: 'auto',
          }}
        >
          <Container maxWidth="md" style={{ textAlign: 'center' }}>
            <Typography variant="h6" component="h2" align="center">
              OVAJ PROJEKT SUFINANCIRAN JE SREDSTVIMA EUROPSKE UNIJE
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              align="center"
              sx={{ fontStyle: 'italic' }}
            >
              Europski poljoprivredni fond za ruralni razvoj
            </Typography>
            <img
              style={{ maxHeight: '180px', height: '20vw' }}
              height="180"
              src={ruralniRazvoj}
              alt="Logo ruralni razvoj"
            />
            <Typography
              variant="h6"
              component="h2"
              align="center"
              sx={{ fontStyle: 'italic' }}
            >
              Nabava strojeva za obavljanje usluga u poljoprivredi
            </Typography>
            <Grid container spacing={0} style={{ textAlign: 'center' }}>
              <Grid item xs style={{ textAlign: 'center' }}>
                <img
                  style={{ maxHeight: '180px', height: '14vw' }}
                  height="180"
                  src={hr}
                  alt="Slika HR zastave"
                />
              </Grid>
              <Grid item xs style={{ textAlign: 'center' }}>
                <img
                  style={{ maxHeight: '180px', height: '14vw' }}
                  height="200"
                  src={eu}
                  alt="Slika EU zastave"
                />
              </Grid>
            </Grid>
          </Container>
          <Typography variant="h6" component="h2">
            Mjera:
          </Typography>
          <Typography
            variant="h6"
            align="justify"
            color="text.secondary"
            paragraph
            gutterBottom
          >
            6.2.1. Potpora razvoju nepoljoprivredne djelatnosti u ruralnim
            područjima.
          </Typography>
          <Typography variant="h6" component="h2">
            Naziv projekta:
          </Typography>
          <Typography
            variant="h6"
            align="justify"
            color="text.secondary"
            paragraph
            gutterBottom
          >
            Nabava strojeva za obavljanje usluga u poljoprivredi.
          </Typography>
          <Typography variant="h6" component="h2">
            Naziv korisnika:
          </Typography>
          <Typography
            variant="h6"
            align="justify"
            color="text.secondary"
            paragraph
            gutterBottom
          >
            OPG Mrmoš Veseljko
          </Typography>
          <Typography variant="h6" component="h2">
            Udio sufinanciranja:
          </Typography>
          <Typography
            variant="h6"
            align="justify"
            color="text.secondary"
            paragraph
            gutterBottom
          >
            85% EU, 15% RH
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Opis projekta:
          </Typography>
          <Typography
            id="modal-modal-description"
            variant="h6"
            align="justify"
            color="text.secondary"
            paragraph
            gutterBottom
          >
            {projectDescription}
          </Typography>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default App;
